import React, { useState, useEffect } from 'react';
import {
	Grid,
	Card,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	TextField,
	Typography,
	Button,
	Modal,
	Box,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { produce } from 'immer';
import { $format } from '../helpers/numberFormat';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import { green, red } from '@material-ui/core/colors';

function KioskSelfPay(props) {
	const [orders, setOrders] = useState([]);
	const [name, setName] = useState({ firstName: '', lastName: '' });
	const [items, setItems] = useState([]);
	const [selectedChabura, setSelectedChabura] = useState();
	const [paymentResult, setPaymentResult] = useState();
	const [lastNameError, setLastNameError] = useState(false);
	const [paymentSubmissionResult, setPaymentSubmissionResult] = useState();
	const [loading, setLoading] = useState(false);
	const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);

	const total = React.useMemo(() => {
		return items.reduce((prev, curr) => {
			if (curr.quantity) {
				return prev + curr.price * curr.quantity;
			}
			return prev;
		}, 0);
	}, [items]);

	useEffect(() => {
		Promise.all([axios.get('/api/orders?status=RUNNING')]).then(([{ data: orders }]) => {
			setOrders(orders);
		});
	}, []);

	useEffect(() => {
		const order = orders.find((o) => o.chaburah_id.toString() === selectedChabura);

		if (order) {
			axios.get(`/api/orders/getPricesOfOrder?orderId=${order.id}`).then(({ data: items }) => {
				setItems(items.map((i) => ({ ...i, quantity: '' })));
			});
		}
	}, [selectedChabura]);

	const onSubmitPayment = async () => {
		const order = orders.find((o) => o.chaburah_id.toString() === selectedChabura);

		const payment_items = items.map((matzahType, index) => ({
			amount: matzahType.quantity || 0,
			matzah_type_id: matzahType.id,
		}));
		const body = {
			orderId: order?.id,
			chaburaId: selectedChabura,
			last_name: name.lastName,
			discount: 0,
			amount: total,
			amountWithFee: (total + total * 0.03).toFixed(2),
			payment_items,
		};
		setLoading(true);
		const { data } = await axios.post('/api/processPayment', body);
		if (data.status === 'Approved') {
			setPaymentSubmissionResult({ success: true, message: 'Payment successful' });
			setDisplaySuccessMessage(true);
			await axios.post(`/api/chaburos/${order?.chaburah_id}/orders/${order?.id}/payments/`, {
				last_name: name.lastName,
				discount: 0,
				amount: total,
				payment_items,
			});
			setTimeout(() => {
				setDisplaySuccessMessage(false);
				window.location.reload();
			}, 750);
		} else {
			setPaymentSubmissionResult({ success: false, message: `${data.status} - ${data.error_message}` });
		}
		setLoading(false);
	};

	return (
		<React.Fragment>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: 32,
					alignItems: 'center',
					marginTop: 100,
				}}
			>
				<FormControl>
					<FormLabel>Chaburos</FormLabel>
					<RadioGroup
						row
						value={selectedChabura}
						onChange={(e, value) => {
							setSelectedChabura(value);
						}}
					>
						{orders.map((o) => (
							<FormControlLabel
								key={o.chaburah_id}
								value={o.chaburah_id.toString()}
								control={<Radio />}
								label={o.nickname}
							/>
						))}
					</RadioGroup>
				</FormControl>
				<div style={{ display: 'flex', gap: 8 }}>
					<TextField
						label='First Name'
						value={name.firstName}
						variant='outlined'
						onChange={(e) => {
							setName(
								produce(name, (draft) => {
									draft.firstName = e.target.value;
								}),
							);
						}}
					/>
					<TextField
						label='Last Name *'
						error={!name.lastName}
						value={name.lastName}
						variant='outlined'
						onChange={(e) => {
							setName(
								produce(name, (draft) => {
									draft.lastName = e.target.value;
								}),
							);
						}}
					/>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
					{items.filter((i) => i.price > 0).map((mt, index) => (
						<div key={mt.matzahtype} style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
							<Typography style={{ width: 75, fontWeight: 'bold' }}>{mt.matzahtype}</Typography>
							<Typography style={{ width: 115 }}>${mt.price} Per Pound</Typography>
							<TextField
								id='outlined-basic'
								variant='outlined'
								type='number'
								size='small'
								value={mt.quantity}
								onChange={(e) => {
									setPaymentResult('');
									setItems(
										produce(items, (draft) => {
											const matzahIndex = draft.findIndex((i) => i.id === mt.id);
											draft[matzahIndex].quantity = e.target.value;
										}),
									);
								}}
							/>
							<div>LBS</div>
						</div>
					))}
				</div>
				<div
					style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 32 }}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: 16,
							justifyContent: 'center',
						}}
					>
						<div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
							<div
								style={{ marginTop: 16, display: 'flex', gap: 8, justifyContent: 'flex-end' }}
							>
								<Typography style={{ width: 75 }}>Subtotal:</Typography>
								<Typography style={{ width: 75 }}>{$format.format(total)}</Typography>
							</div>
							<div style={{ display: 'flex', gap: 8, justifyContent: 'flex-end' }}>
								<Typography style={{ width: 75 }}>CC 3%: </Typography>
								<Typography style={{ width: 75 }}>{$format.format(total * 0.03)}</Typography>
							</div>
							<div
								style={{ marginTop: 16, display: 'flex', gap: 8, justifyContent: 'flex-end' }}
							>
								<Typography style={{ width: 75 }}>Total:</Typography>
								<Typography style={{ width: 75 }}>
									{$format.format(total + total * 0.03)}
								</Typography>
							</div>
						</div>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							gap: 8,
							flexDirection: 'column',
						}}
					>
						<>
							<Button
								className={props.classes.button}
								variant='contained'
								size='large'
								color='primary'
								disabled={!name.lastName || total <= 0 || loading}
								onClick={onSubmitPayment}
								loading={loading}
							>
								Pay Now
							</Button>
							{paymentSubmissionResult &&
								paymentSubmissionResult.success &&
								displaySuccessMessage && (
									<CheckCircleOutlineIcon style={{ color: green[500] }} />
								)}
						</>
						{lastNameError && <div style={{ color: 'red', marginTop: 8 }}>Please Enter Name</div>}
						:
						{paymentSubmissionResult && !paymentSubmissionResult.success && (
							<div style={{ display: 'flex', gap: 8 }}>
								<ClearIcon style={{ color: red[500] }} />
								<div style={{ color: 'red' }}>{paymentSubmissionResult.message}</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<Modal open={loading} aria-labelledby='loading-modal'>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 4,
						borderRadius: 2,
						textAlign: 'center',
					}}
				>
					<CircularProgress />
					<Typography variant='h6' sx={{ mt: 2 }}>
						Please finish selection on the credit card terminal
					</Typography>
				</Box>
			</Modal>
		</React.Fragment>
	);
}

const styles = (theme) => ({
	root: {
		minHeight: '100vh',
		margin: '100px auto',
	},
	orderCard: {
		height: '420px',
		padding: '20px',
		textAlign: 'center',
	},
	button: {
		height: '50px',
	},
});

export default withStyles(styles)(KioskSelfPay);
